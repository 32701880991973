exports.columns = [
  { data: 'id' }
  { data: 'service' }
  { data: 'store' }
  { data: 'customer' }
  { data: 'date' }
  { data: 'h_star' }
  { data: 'h_end' }
  { data: 'condition' }
  {data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap'}
]
exports.config = {}
