exports.columns = [
  {data: 'id'},
  {data: 'tax_ica'},
  {data: 'tax_autorenta'},
  {data: 'comm_bildu'},
  {data: 'comm_bildu_iva'},
  {data: 'cost_facturae'},
  {data: 'cost_facturae_iva'},
  {data: 'created_at'},
]
exports.config = {}
