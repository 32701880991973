exports.columns = [
  { data: 'id' }
  { data: 'name' }
  { data: 'category' }
  { data: 'subcategory' }
  { data: 'subcategory_type' }
  { data: 'company' }
  { data: 'brand' }
  { data: 'cause' }
  { data: 'sold_units' }
  { data: 'price' }
  { data: 'discount', sortable: false, searchable: false }
  { data: 'status' }
  { data: 'approved' }
  { data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap' }
]

exports.config = {}
