exports.columns = [
  {data: 'id'},
  {data: 'cause'},
  {data: 'buyer'},
  {data: 'father'},
  {data: 'grandfather'},
  {data: 'great_grandfather'},
  {data: 'foundation'},
  {data: 'created_at'},
]
exports.config = {}
