exports.columns = [
  {data: 'id'},
  {data: 'name'},
  {data: 'document'},
  { data: 'phone' },
  { data: 'email' },
  { data: 'mobile' },
  {data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap'}
]
exports.config = {}
