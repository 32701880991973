exports.columns = [
  { data: 'id' }
  { data: 'amount' }
  { data: 'payment_date' }
  { data: 'created_at' }
  { data: 'condition' }
  {data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap'}

]

exports.config = {}
