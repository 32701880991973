exports.columns = [
  {data: 'id'},
  {data: 'item'},
  {data: 'person'},
  {data: 'amount'},
  {data: 'settle'},
  {data: 'paid'},
  {data: 'date'},
]
exports.config = {}
