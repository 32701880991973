exports.columns = [
  {data: 'id'},
  {data: 'type'},
  {data: 'comm_pgw_static'},
  {data: 'comm_pgw_variable'},
  {data: 'comm_pgw_iva'},
  {data: 'retefuente'},
  {data: 'reteica'},
  {data: 'reteiva'},
  {data: 'created_at'},
]
exports.config = {}
