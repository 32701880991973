exports.columns = [
  { data: 'id' }
  { data: 'title' }
  { data: 'price_without_dc' }
  { data: 'published_discount' }
  { data: 'published_price' }
  { data: 'discount_type', sortable: false, searchable: false }
  { data: 'discount_status', sortable: false, searchable: false }
]

exports.config = {
  dom: 'lBfrtip'
  lengthMenu: [
    20, 50, 100, 300, 500
  ]
  buttons: [
    {
      extend: 'colvis'
      text: '<span>Columnas</span>'
    }
    {
      extend: 'excel'
      text: '<span uk-icon="icon: download"></span>'
      exportOptions:
        columns: ':visible'
    }
    {
      extend: 'print'
      text: '<span uk-icon="icon: print" ></span>'
      exportOptions:
        columns: ':visible'
    }
    {
      extend: 'csv'
      bom: true
      text: '<span>CSV</span>'
      charset: 'utf-8'
      extension: '.csv'
      fieldSeparator: ';'
      fieldBoundary: '',
      filename: 'Precios Kryon'
      export_options: [
        0, 1, 2, 3, 4, 5, 6
      ]
    }
  ]
}
