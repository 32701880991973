exports.columns = [
  {data: 'id'},
  {data: 'cart'},
  {data: 'item'},
  {data: 'base'},
  {data: 'profit_base'},
  {data: 'consortium'},
  {data: 'operator'},
  {data: 'agent_son'},
  {data: 'cause'},
  {data: 'network'},
  {data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap'}
]
exports.config = {}
