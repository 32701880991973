exports.columns = [
  { data: 'id' }
  { data: 'title' }
  { data: 'price_without_dc' }
  { data: 'published_discount' }
  { data: 'published_price' }
  { data: 'percentage' }
  { data: 'available_from' }
  { data: 'available_until' }
  { data: 'discount_status', sortable: false, searchable: false }
]

exports.config = {}
