exports.columns = [
  { data: 'id' }
  { data: 'customer' }
  { data: 'phone' }
  { data: 'email' }
  { data: 'message' }
  { data: 'created_at' }
  { data: 'state' }
  { data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap'}
]

exports.config = {}
